import { PersistProvider } from 'providers/PersistProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import json from './package.json';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
	return (
		<>
			<Helmet>
				<meta name="version" content={json.version} />
			</Helmet>

			<ThemeProvider>
				<PersistProvider>
					{element}
					<ToastContainer
						position="bottom-left"
						autoClose={5000}
						newestOnTop={false}
						pauseOnHover
					/>
				</PersistProvider>
			</ThemeProvider>
		</>
	);
};
