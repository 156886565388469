import { useState } from 'react';
import { getParamNames, isBrowser } from 'utils/helper';

const initialState = {};

const getStoredValue = (key, keyName, initialValue) => {
	if(isBrowser) {
		if (key instanceof Function) {
			const mainState = window.localStorage.getItem(keyName);
			const nestedState = key(JSON.parse(mainState));
			return nestedState ? nestedState : initialValue;
		} else {
			const item = window.localStorage.getItem(keyName);
			return item?.includes('{') ? JSON.parse(item) : item ? item : initialValue;
		}
	}
	return initialValue;
};

export const getLocalStorage = (key, initialValue = initialState) => {
	const keyName = key instanceof Function ? getParamNames(key)[0] : key;
	return getStoredValue(key, keyName, initialValue);
};

// use-local-storage from npm
export default function useLocalStorage(key, initialValue = initialState) {
	const keyName = key instanceof Function ? getParamNames(key)[0] : key;
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => getStoredValue(key, keyName, initialValue));

	const setValue = value => {
		if(isBrowser) {
			try {
				let valueToStore = value;
				// Allow value to be a function so we have same API as useState (else)
				if (value instanceof Function) {
					valueToStore = value(() => {
						const item = window.localStorage.getItem(keyName);
						return item?.includes('{') ? JSON.parse(item) : item ? item : initialValue;
					});
				}

				if(typeof valueToStore === 'string') {
					setStoredValue(valueToStore);
					window.localStorage.setItem(keyName, valueToStore);
					// is Object with properties
				} else {
					const oldState = JSON.parse(window.localStorage.getItem(keyName));
					const restoredState = oldState ? oldState : initialValue;
					const newState = ({ ...restoredState, ...valueToStore });

					setStoredValue(newState);

					window.localStorage.setItem(keyName, JSON.stringify(newState));
				}
			} catch(error) {
				// A more advanced implementation would handle the error case
				console.log(error);
			}
		}
	};

	return [
		storedValue,
		setValue
	];
}
