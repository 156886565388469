Headers.prototype.getFileName = function (name) {
	return this.get(name)
		.split('filename=')[1]
		.replaceAll('"', '');
};

String.prototype.cut = function (char) {
	return this.replaceAll(char, '');
};

String.prototype.withSlash = function () {
	return this.replace(/\/?$/, '/');
};

String.prototype.toUpperCaseChar = function (at = 0, start = 1) {
	return this.charAt(at).toUpperCase() + this.slice(start);
};


