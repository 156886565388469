import { plPL } from '@mui/material/locale';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { createContext } from 'use-context-selection';
import { setProperty } from 'utils/helper';

const defaultState = {
	theme: {
		typography: {
			// In Chinese and Japanese the characters are usually larger,
			// so a smaller fontsize may be appropriate.
			fontSize: 16,
			fontFamily: '"Fira Sans", "ui-sans-serif", sans-serif'
		},
		overrides: {
			MuiTableCell: {
				root: {
					fontFamily: '"Fira Sans", "ui-sans-serif", sans-serif',
					fontSize: '1rem',
				}
			},
			MuiTablePagination: {
				select: {
					fontFamily: '"Fira Sans", "ui-sans-serif", sans-serif',
					fontSize: '1rem',
				},
			},
			MuiTypography: {
				root: {
					'&.MuiTypography-body2': {
						fontFamily: '"Fira Sans", "ui-sans-serif", sans-serif',
						fontSize: '1rem',
					},
				},
			},
			MuiTooltip: {
				popper: {
				}
			},
			MuiPopover: {
				root: {
				}
			}
		},
		palette: {
			primary: { main: '#0A9E50' },
			secondary: { main: '#006BFF' }
		},
	},
	setTheme: () => {}
};

const Context = createContext(defaultState);

function ThemeProvider({ children }) {
	const [theme, updateTheme] = useState(createTheme(defaultState.theme, plPL));

	const setTheme = object => {
		updateTheme(createTheme(object));
	};

	const setPrimaryColor = value => {
		setProperty('--color-primary', value);

		updateTheme(createTheme({
			...defaultState.theme,
			palette: {
				...defaultState.theme.palette,
				primary: { main: value }
			}
		}));
	};

	return (
		<Context.Provider
			value={{
				theme,
				setTheme,
				setPrimaryColor
			}}
		>
			<MuiThemeProvider theme={theme}>
				{children}
			</MuiThemeProvider>
		</Context.Provider>
	);
}

export { ThemeProvider };

export default Context;
