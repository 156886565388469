import './layout.css';
import '@fontsource/fira-sans/500.css';
import '@fontsource/fira-sans/400.css';
import '@fontsource/fira-sans/700.css';
import 'react-toastify/dist/ReactToastify.css';
import './prototypes.js';

import { getStorageWebpackVersion } from 'storage/storageGets';
import { setStorageWebpackVersion } from 'storage/storageSets';

import wrapRoot from './wrap-root';

export const wrapRootElement = wrapRoot;

export const onRouteUpdate = () => {
	updateAppVersion();
};

const updateAppVersion = () => {
	const latestHash = getStorageWebpackVersion();
	fetch('/page-data/app-data.json', {
		headers : {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	})
		.then(response => response.json())
		.then(({ webpackCompilationHash }) => {
			if(
				webpackCompilationHash && window.___webpackCompilationHash &&
				(webpackCompilationHash !== window.___webpackCompilationHash && webpackCompilationHash !== latestHash)
			) {
				setStorageWebpackVersion(webpackCompilationHash); // for all windows

				setTimeout(() => {
					// WARNING: you can't really clear cache (infinity reload loop) by caches.delete(name);
					window.location.reload(); // default clear cache
				}, 1000);
			}
		});
};
