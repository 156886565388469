import { ENUM_STORAGE_WEBPACK_VERSION } from 'storage/storageActions';
import { isBrowser } from 'utils/helper';

export const getStorageWebpackVersion = () => {
	let token = null;
	if(isBrowser) {
		token = sessionStorage.getItem(ENUM_STORAGE_WEBPACK_VERSION);
	}
	return token;
};
