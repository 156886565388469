export const CONST = {
	KEY: {
		SUBMIT: { key: 'Enter', code: 'Enter', which: 13 },
		LEFT_ARROW: { key: 'ArrowLeft', code: 'ArrowLeft', which: 37 },
		RIGHT_ARROW: { key: 'ArrowRight', code: 'ArrowRight', which: 39 },
		T: { key: 't', code: 'KeyT', which: 84 }
	},
	OPTION: {
		EMPTY: 'empty'
	},
	PL: 'pl',
	PLN: 'PLN'
};

export const ENUM_PAGE = {
	SMARTFAKTOR: 1,
	FINTEQ: 2
};

export const ENUM_SOURCE = {
	// supply-chain-financing
	LP1_ANIMATOR_FORM: {
		source: 1,
		sourceId: 'DD-ANIM-FORM-LP1'
	},
	// cennik-i-plany
	PRICING_FORM: {
		source: 2,
		sourceId: 'DD-ANIM-ORDER'
	},
	// contact
	CONTACT_FORM: {
		source: 3,
		sourceId: 'DD-ANIM-CONTACT'
	},
	// discount-your-invoice
	SUPPLIER_FORM: {
		source: 4,
		sourceId: 'DD-SUPPL-FORM'
	},
	// 2000
	LP2_SUPPLIER_FORM_BUDMA_2000: {
		source: 5,
		sourceId: 'DD-SUPPL-FORM-2000-LP2'
	},
	// pokonaj-inflacje - copy page from supply-chain-financing
	INFLATION_FORM: {
		source: 6,
		sourceId: 'DD-ANIM-FORM-INFL'
	}
};

export const ENUM_FAQ_CATEGORY = {
	PRICING_PAGE: 'cennik',
	SUPPLIER_PAGE: 'zgloszenie-animatora'
};
