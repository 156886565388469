import React, { createContext, useContext, useReducer } from 'react';
import { updateObject } from 'utils/helper';

const PersistStateContext = createContext();
const PersistDispatchContext = createContext();

const initialState = {
	// TODO: change place
	option: {
		duration: 12,
		durationInMonths: 12,
		durationUnit: 1,
		id: '1'
	},
	object: {},
	array: [],
	persistData: {}
};

const actionsPersist = {
	updateOption: 'updateOption',
	updatePersistObject: 'updatePersistObject',
	updatePersistArray: 'updatePersistArray',
	updatePersistData: 'updatePersistData',
};

function mainReducer(state, { type, payload }) {
	switch (type) {
		case actionsPersist.updatePersistObject: {
			return updateObject(state, {
				object: payload,
			});
		}
		case actionsPersist.updatePersistArray: {
			return updateObject(state, {
				array: payload,
			});
		}
		case actionsPersist.updatePersistData: {
			return updateObject(state, {
				persistData: payload,
			});
		}
		case actionsPersist.updateOption: {
			return updateObject(state, {
				option: payload,
			});
		}
		default: {
			throw new Error(`Unhandled action type: ${type}`);
		}
	}
}

function PersistProvider({ children }) {
	const [state, dispatch] = useReducer(mainReducer, initialState);

	return (
		<PersistStateContext.Provider value={state}>
			<PersistDispatchContext.Provider value={dispatch}>
				{children}
			</PersistDispatchContext.Provider>
		</PersistStateContext.Provider>
	);
}

function usePersistState() {
	const context = useContext(PersistStateContext);
	if (context === undefined) {
		throw new Error('usePersistState must be used within a PersistProvider');
	}
	return context;
}

function usePersistDispatch() {
	const context = useContext(PersistDispatchContext);
	if (context === undefined) {
		throw new Error('usePersistDispatch must be used within a PersistProvider');
	}
	return context;
}

export { PersistProvider, usePersistState, usePersistDispatch, actionsPersist };
